import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import { ReportTemplate } from "@socotec.io/socio-vue-components";
import SspReport from "@/models/SspReport";
import { STATUSES } from "@/constants/reports-list/statuses.js";
import i18n from "@/setup/i18n";
import { snakeToCamel } from "@/utils/utilsGrcpRest";

const messages = {
  en: {
    reportGenerationFailed:
      "An error occurred while generating the report, please try again",
    reportGenerationSuccessfully: "The report has been successfully generated",
    reportValidationSuccess: "Report '{0}' has been validated successfully",
    reportValidationError: "Error when validate report '{0}'",
  },
  fr: {
    reportGenerationFailed:
      "Une erreur s'est produite pendant la génération du report veuillez réssayer",
    reportGenerationSuccessfully: "Le rapport a été généré",
    reportValidationSuccess: "Le rapport '{0}' a été validé",
    reportValidationError: "Erreur pendant la validation du rapport '{0}'",
  },
};

i18n.mergeLocaleMessage("fr", messages.fr);
i18n.mergeLocaleMessage("en", messages.en);

const SET_IS_GENERATION_PROCESSING = "SET_IS_GENERATION_PROCESSING";
const SET_GENERATION_STEP_DATA = "SET_GENERATION_STEP_DATA";
const CLEAR_GENERATIONS_STEPS_DATA = "CLEAR_GENERATIONS_STEPS_DATA";
const UPDATE_REPORTS_COUNT = "UPDATE_REPORTS_COUNT";
const state = {
  isGenerationProcessing: false,
  currentGenerationSteps: new Map(),
  reportsCount: 0,
};

const getters = {
  getReportsOrderedByDate() {
    return SspReport.query().orderBy("createdAt", "desc").get();
  },
  getReportsCount: () => {
    return state.reportsCount;
  },
  getTemplateByName: () => (name) => {
    return ReportTemplate.query().where("name", name).first();
  },
  isGenerationProcessing: (state) => {
    return state.isGenerationProcessing;
  },
  // Entire Map step data
  getSteps: (state) => {
    return state.currentGenerationSteps;
  },
  // All entries of specific step
  getStepEntries: (state) => (stepCode) => {
    return state.currentGenerationSteps.get(stepCode) ?? {};
  },
  // Check if a step have been instanciated
  isStepActionned: (state) => (stepCode) => {
    return state.currentGenerationSteps.has(stepCode);
  },
  // Get step sub-value
  getStepEntryValue: (state) => (stepCode, key) => {
    const generationStepsData = state.currentGenerationSteps.get(stepCode);
    if (
      generationStepsData &&
      Object.prototype.hasOwnProperty.call(generationStepsData, key)
    ) {
      return generationStepsData[key];
    }
    return;
  },
};

function onReportGenerationFail(dispatchCallable, message) {
  dispatchCallable("notifications/showErrorNotification", message, {
    root: true,
  });
}
async function onReportGenerationSuccess(dispatchCallable, message) {
  dispatchCallable("notifications/showSuccessNotification", message, {
    root: true,
  });
}
const actions = {
  async createReport(
    { dispatch, commit, rootGetters },
    { templateCode, report }
  ) {
    const request =
      new socioGrpcClient.ssp_back.report.ReportGenerationRequest();
    request.setTemplateCode(templateCode);
    request.setRecipientsList(report?.recipientsList);
    request.setRecipientUsermanagementUuidsList(
      report?.recipientUsermanagementUuidsList
    );
    request.setProjectUuid(rootGetters["missions/currentMission"].uuid);
    try {
      commit(SET_IS_GENERATION_PROCESSING, true);
      const stream =
        await socioGrpcClient.ssp_back.report.ReportControllerPromiseClient.generate(
          request,
          sspMetadata()
        );
      stream.on("data", async (response) => {
        const data = snakeToCamel(response.getData().toJavaScript());
        await SspReport.insert({
          data: {
            ...data,
            isInGeneration: true,
          },
        });
        await commit("UPDATE_REPORTS_COUNT", await SspReport.query().count());
        if (response.getCode() != STATUSES.GENERATING) {
          if (response.getCode() != STATUSES.SUCCESS) {
            onReportGenerationFail(dispatch, i18n.t("reportGenerationFailed"));
          } else {
            onReportGenerationSuccess(
              dispatch,
              i18n.t("reportGenerationSuccessfully")
            );
          }
          SspReport.update({
            where: data.uuid,
            data: { isInGeneration: false, status: data.status },
          });
        }
      });
      return stream;
    } catch (err) {
      console.error(err);
    } finally {
      commit(SET_IS_GENERATION_PROCESSING, false);
    }
  },

  async validateReport(
    { dispatch, commit, rootGetters },
    { reportUuid, reportName }
  ) {
    const request =
      new socioGrpcClient.ssp_back.report.ReportValidationRequest();

    request.setReportUuid(reportUuid);
    request.setProjectUuid(rootGetters["missions/currentMission"].uuid);

    try {
      commit(SET_IS_GENERATION_PROCESSING, true);
      const stream =
        await socioGrpcClient.ssp_back.report.ReportControllerPromiseClient.validate(
          request,
          sspMetadata()
        );

      stream.on("data", async (response) => {
        const data = snakeToCamel(response.getData().toJavaScript());

        SspReport.update({
          where: data.uuid,
          data: { isInGeneration: true, status: data.status },
        });

        if (response.getCode() != STATUSES.GENERATING) {
          if (response.getCode() != STATUSES.SUCCESS) {
            onReportGenerationFail(
              dispatch,
              i18n.t("reportValidationError", [reportName])
            );
          } else {
            onReportGenerationSuccess(
              dispatch,
              i18n.t("reportValidationSuccess", [reportName])
            );
          }
          SspReport.update({
            where: data.uuid,
            data: { isInGeneration: false, status: data.status },
          });
        }
      });
      return stream;
    } catch (err) {
      console.error(err);
    } finally {
      commit(SET_IS_GENERATION_PROCESSING, false);
    }
  },

  async invalidateReport({ rootGetters }, { reportUuid }) {
    const request =
      new socioGrpcClient.ssp_back.report.ReportValidationRequest();

    request.setReportUuid(reportUuid);
    request.setProjectUuid(rootGetters["missions/currentMission"].uuid);

    const response =
      await socioGrpcClient.ssp_back.report.ReportControllerPromiseClient.invalidate(
        request,
        sspMetadata()
      );

    const data = snakeToCamel(response.getData().toJavaScript());

    SspReport.update({
      where: reportUuid,
      data: data,
    });
  },

  async fetchReportTemplateList() {
    const metadata = {
      filters: {
        service_id: process.env.VUE_APP_SSP_SERVICE_ID,
        hidden:false
      },
    };
    const request =
      new socioGrpcClient.report_generator.reports.ReportTemplateListRequest();
    const response =
      await socioGrpcClient.report_generator.reports.ReportTemplateControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    return await ReportTemplate.insert({
      data: response.toObject().resultsList,
    });
  },
  /**
   * Fetch report from µService report-generator
   * @param [commit]
   * @param metadata
   * @param projectId
   * @returns {Promise<*>}
   */
  async fetchReportList({ commit }, metadata) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportListRequest();
    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const responseObjects = response.toObject().resultsList;
    commit(UPDATE_REPORTS_COUNT, response.getCount());
    return await SspReport.create({
      data: responseObjects,
    });
  },
  async retrieveReport(context, reportUuid) {
    const request = new socioGrpcClient.report_generator.reports.Report();
    request.setUuid(reportUuid);
    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.retrieve(
        request,
        {}
      );
    const reportData = response.toObject();
    reportData.reportGenerationData = response
      .getReportGenerationData()
      .toJavaScript();
    const reportInsert = await SspReport.insert({
      data: reportData,
    });
    return reportInsert.reports[0];
  },

  async deleteReport({ commit }, { uuid }) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportDestroyRequest();
    request.setUuid(uuid);

    await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.destroy(
      request,
      {}
    );
    await SspReport.delete(uuid);
    await commit(UPDATE_REPORTS_COUNT, await SspReport.query().count());
  },

  async updateReportStatus(_, { reportUuid, status }) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportUpdateStatusRequest();
    request.setUuid(reportUuid);
    request.setStatus(status);

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.updateStatus(
        request,
        {}
      );

    const { data } = response.toObject();

    await SspReport.update({
      where: reportUuid,
      data: data,
    });
  },
  createOrUpdateStepEntry({ commit, rootGetters }, { stepCode, key, value }) {
    // Call step data mutations, don't use it directly from component: use composition wrapper instead.
    const stepData = rootGetters["reportslist/getStepEntries"](stepCode);
    stepData[key] = value;
    commit(SET_GENERATION_STEP_DATA, { stepCode, stepData });
  },
  async resetStepsEntries({ commit }) {
    commit(CLEAR_GENERATIONS_STEPS_DATA);
  },
};
const mutations = {
  [UPDATE_REPORTS_COUNT]: (state, newTotal) => {
    state.reportsCount = newTotal;
  },
  [SET_IS_GENERATION_PROCESSING]: function (state, newValue) {
    state.isGenerationProcessing = newValue;
  },
  [SET_GENERATION_STEP_DATA]: function (state, { stepCode, stepData }) {
    state.currentGenerationSteps.set(stepCode, stepData);
  },
  [CLEAR_GENERATIONS_STEPS_DATA]: function (state) {
    state.currentGenerationSteps = new Map();
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state,
};
