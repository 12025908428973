import AnalysisRequest from "@/models/AnalysisRequest";
import ComplementaryAnalysisRequest from "@/models/ComplementaryAnalysisRequest";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata } from "@/utils/metadata";
import { responseTojavascript } from "@/utils/utilsGrcpRest";

const state = {
  analysisCount: 0,
};

const mutations = {
  SET_ANALYSIS_COUNT: (state, count) => {
    state.analysisCount = count;
  },
};

const getters = {
  getAnalysisRequests: () => {
    return AnalysisRequest.query().get();
  },
  getCombinedAnalysisRequests: () => {
    const analysisRequests = AnalysisRequest.query().get();
    const complementaryAnalysisRequests =
      ComplementaryAnalysisRequest.query().get();
    return [...analysisRequests, ...complementaryAnalysisRequests];
  },
};

const actions = {
  async fetchAnalysisRequests(_, metadata) {
    const request =
      new socioGrpcClient.ssp_back.intervention.AnalysisRequestListRequest();
    const response =
      await socioGrpcClient.ssp_back.intervention.AnalysisRequestControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const { data } = responseTojavascript(response);

    AnalysisRequest.create({
      data: data,
    });
  },
  async fetchComplementaryAnalysisRequests(_, metadata) {
    const request =
      new socioGrpcClient.ssp_back.intervention.ComplementaryAnalysisRequestListRequest();
    const response =
      await socioGrpcClient.ssp_back.intervention.ComplementaryAnalysisRequestControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const { data } = responseTojavascript(response);

    ComplementaryAnalysisRequest.create({
      data: data,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
